import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocuments, toggleUploadDocumentModal, toggleDeleteDocumentModal } from '../../features/documentsSlice';
import Loading from '../common/Loading';
import translate from '../../Services/translate';
import { FiTrash2 } from "react-icons/fi";


const MyDocuments = () => {

   const dispatch = useDispatch();

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   const { documents, document_loading } = useSelector(({ document }) => document);


   useEffect(() => {
      dispatch(getDocuments())
      // eslint-disable-next-line
   }, [])

   const openFullscreen = (i) => {
      let elem = document.getElementById('doc-' + i);
      if (elem.requestFullscreen) {
         elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
         elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
         elem.msRequestFullscreen();
      }
   }


   return (
      <div className='pb-8'>
         <div className='self-center pb-1 text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-between'>
            <h2 className='text-lg md:text-2xl font-Stencil'>{translate(132)}</h2>
         </div>
         <div className='flex justify-end pt-3 pr-4' >
            <button
               onClick={() => dispatch(toggleUploadDocumentModal({ value: true }))}
               className='rounded-md w-40 bg-primary-100 hover:bg-lime-600 py-2 text-white'
            >
               {translate(268)}
            </button>
         </div>
         {!document_loading ?
            <>
               {documents && documents?.length ?
                  <div className='pt-6 px-4'>
                     <div className='grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-8'>
                        {
                           documents?.map((_document, i) => (
                              <div
                                 title={translate(11)}
                                 key={i}
                                 className='relative rounded-lg border border-gray-300 h-64 w- shadow-sm shadow-stone-300 cursor-pointer'
                                 onClick={() => openFullscreen(i)}
                              >
                                 <div className='h-[15%] border-b border-gray-300 flex justify-center items-center text-white bg-stone-400 rounded-t-lg'>
                                    {_document?.required_document?.name}
                                 </div>
                                 <div className='h-[85%] object-cover flex justify-center items-center text-gray-500'>
                                    <img id={`doc-${i}`} src={_document?.file} alt='' className='h-full object-contain' />
                                 </div>
                                 <button onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(toggleDeleteDocumentModal({ value: _document.id }));
                                 }} className='bg-white absolute bottom-1 right-0 p-2 rounded-lg' ><FiTrash2 size={24} color={"red"} /></button>
                              </div>
                           ))
                        }
                     </div>
                  </div>
                  :
                  <div className='w-full flex justify-center pt-12 text-sky-600 font-semibold'>{translate(40)}</div>
               }
            </>
            :
            <div className='pt-3'>
               <Loading loading={document_loading} loadingStyle='text-sky-600 w-7 h-7' />
            </div>
         }
      </div>
   )
}

export default MyDocuments;
