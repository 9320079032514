import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import myModel from "../Services/api";
import axios from "axios";
import AuthService from "../Services/AuthService";

const initialState = {
  document_loading: false,
  requiredList_loading: false,
  document_loading2: false,
  document_status: "",
  documents: [],
  requiredList: [],
  show_upload_document_modal: false,
  show_delete_doc_modal: false,
  show_document_needed_modal: false,
};

export const getRequiredDocumentsList = createAsyncThunk(
  "document/getRequiredDocumentsList",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await myModel.find("system/required-documents-list");
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDocuments = createAsyncThunk(
  "document/getDocuments",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await myModel.find("my-documents/");
      console.log("11111111111",response)
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadDocuments = createAsyncThunk(
  "document/uploadDocuments",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await myModel.uploadMyDoc("my-documents/", arg);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteDocuments = createAsyncThunk(
  "document/deleteDocuments",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await myModel.deleteDoc(`my-documents/${arg}`);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const documentsSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    toggleUploadDocumentModal: (state, { payload: { value } }) => {
      state.show_upload_document_modal = value;
    },
    toggleDeleteDocumentModal: (state, { payload: { value } }) => {
      state.show_delete_doc_modal = value;
    },
    toggleDocumentNeededModal: (state, { payload: { value } }) => {
      state.show_document_needed_modal = value;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getDocuments.pending, (state) => {
      state.document_loading = true;
    });
    addCase(getDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload;
      state.document_loading = false;
    });
    addCase(getDocuments.rejected, (state) => {
      state.document_loading = false;
    });
    addCase(getRequiredDocumentsList.pending, (state) => {
      state.requiredList_loading = true;
    });
    addCase(getRequiredDocumentsList.fulfilled, (state, { payload }) => {
      state.requiredList = payload;
      state.requiredList_loading = false;
    });
    addCase(getRequiredDocumentsList.rejected, (state) => {
      state.requiredList_loading = false;
    });

    addCase(uploadDocuments.pending, (state) => {
      state.document_status = "pending";
      state.document_loading2 = true;
    });
    addCase(uploadDocuments.fulfilled, (state) => {
      state.document_status = "success";
      state.document_loading2 = false;
      state.show_upload_document_modal = false;

    });
    addCase(uploadDocuments.rejected, (state) => {
      state.document_status = "failed";
      state.document_loading2 = false;
      state.show_upload_document_modal = false;

    });
    addCase(deleteDocuments.pending, (state) => {
      state.document_status = "pending";
      state.document_loading2 = true;
    });
    addCase(deleteDocuments.fulfilled, (state) => {
      state.document_status = "success";
      state.document_loading2 = false;
      state.show_delete_doc_modal = false;
    });
    addCase(deleteDocuments.rejected, (state) => {
      state.document_status = "failed";
      state.document_loading2 = false;
      state.show_delete_doc_modal = false;
    });
  },
});

export const { toggleUploadDocumentModal, toggleDeleteDocumentModal,toggleDocumentNeededModal } =
  documentsSlice.actions;
export default documentsSlice.reducer;
